/* roboto-slab-100 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/roboto-slab-v24-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-slab-v24-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab-v24-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab-v24-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab-v24-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab-v24-latin-100.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

/* roboto-slab-300 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-slab-v24-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-slab-v24-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab-v24-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab-v24-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab-v24-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab-v24-latin-300.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-slab-v24-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-slab-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab-v24-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-slab-v24-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-slab-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab-v24-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab-v24-latin-700.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

/* dosis-regular - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/dosis-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/dosis-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/dosis-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/dosis-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/dosis-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/dosis-v27-latin-regular.svg#Dosis') format('svg'); /* Legacy iOS */
}

/* dosis-500 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/dosis-v27-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/dosis-v27-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/dosis-v27-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/dosis-v27-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/dosis-v27-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/dosis-v27-latin-500.svg#Dosis') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'FontAwesome';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/fontawesome-webfont.eot');
  src: url('../fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/fontawesome-webfont.woff2') format('woff2'),
       url('../fonts/fontawesome-webfont.woff') format('woff'),
       url('../fonts/fontawesome-webfont.ttf') format('truetype'),
       url('../fonts/fontawesome-webfont.svg#fontawesomeregular') format('svg');
}

