// SASS Vars

$white: #fff;
$black: #000;
$white-80: rgba($white, .8);
$white-15: rgba($white, .15);
$black-80: rgba($black, .8);
$black-60: rgba($black, .6);
$black-50: rgba($black, .5);
$black-40: rgba($black, .4);
$black-33: rgba($black, .33);
$black-25: rgba($black, .25);
$black-20: rgba($black, .20);
$black-15: rgba($black, .15);
$black-08: rgba($black, .08);

$gray-01: #111;
$gray-02: #222;
$gray-03: #333;
$gray-04: #444; // $text
$gray-05: #555;
$gray-06: #666;
$gray-07: #777;
$gray-08: #888;
$gray-09: #999;
$gray-10: #aaa; // 33%
$gray-11: #bbb;
$gray-12: #ccc; // 20%
$gray-13: #ddd;
$gray-14: #eee;


$lila: #a34db5;
$lila-light: #c081cc;
$lila-25: rgba($lila-light, .25);

// Edit Links

$edit-link-border-color: rgba(0, 0, 0, .135); // #DDD;
$edit-link-border-color-hover: rgba(0, 0, 0, 0); // transparent
$edit-link-bg-color: rgba(0, 0, 0, 0);  // transparent
$edit-link-bg-color-hover: rgba(0, 0, 0, .065); // #EEE
$edit-link-text: $black-50;
$edit-link-text-hover: $black-80;

$light-font: $gray-09;
$dark-font: $gray-04;
$carousel-bg: rgba(0, 0, 0, .065); // #EEE
$carousel-bg-light: rgba(0, 0, 0, .048); // #f3f3f3;


// Text

$text: $gray-04;
$linked-text: $gray-03;
$linked-text-hover: $black;



// Source: processwire-master/site-languages/templates/styles/main.css

//
// main.css
//
// 1. General HTML tags
// 2. Masthead area
// 3. Main content and sidebar
// 4. Footer
// 5. Media queries for responsive layout
// 6. Accessibility helpers
//
//

// ********************************************************************
// 1. General HTML tags
//
//

* {
  box-sizing: border-box;
}

body {
  // padding: 5%;
  // max-width: 1600px;
  // margin: 0 auto;
  margin: 0;
  padding: 0;
  width: 100%;
}

body, td, input[type=text], textarea {
  color: $text;
  // font-family: 'Quattrocento', serif;
  font-family: 'Roboto Slab', serif;
  // font-size: 105%;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.8;
}

img {
  max-width: 100%;
}

h1 {
  .edit a {

    &:link,
    &:visited {
      color: $edit-link-text;
      font-family: 'Roboto Slab', serif;
      letter-spacing: 0;
      text-transform: none;
    }

    &:hover,
    &:active,
    &:focus {
      color: $edit-link-text-hover;
    }
  }
}

h2 {
  border-top: 1px solid $black-25;
  color: $black-60;
  font-family: 'Dosis', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  margin-bottom: 1.5em;
  margin-top: 1em;
  padding-top: .5em;
  text-transform: uppercase;

  .edit a {

    &:link,
    &:visited {
      color: $edit-link-text;
      font-family: 'Roboto Slab', serif;
      letter-spacing: 0;
      text-transform: none;
    }

    &:hover,
    &:active,
    &:focus {
      color: $edit-link-text-hover;
    }
  }
}

h3 {
  border-top: 1px solid $black-15;
  color: $black-40;
  font-family: 'Dosis', sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 1.5em;
  margin-top: 2.5em;
  // padding-top: .5em;
  padding-top: 1em;
  text-transform: uppercase;
}


.section:nth-child(even) h3 {
  border-top: 1px solid $white-15;
}

// .section--inner > h3:nth-of-type(3n+1),
// .section--inner > h3 + p:nth-of-type(3n+1) {
//   margin-left: 30%;
//   width: 70%;
// }
// .section--inner > h3:nth-of-type(3n+2),
// .section--inner > h3 + p:nth-of-type(3n+2) {
//   margin-left: 10%;
//   width: 70%;
// }
// .section--inner > h3:nth-of-type(3n+3),
// .section--inner > h3 + p:nth-of-type(3n+3) {
//   margin-left: 20%;
//   width: 70%;
// }




a {
  border-bottom: 1px solid $black-20;
  color: $linked-text;
  text-decoration: none;

  &.no-border {
    border-bottom: 0 none;
  }
}

.about-me a {
  border-bottom: 0 none;
}

a:hover,
.nav a:hover {
  border-color: $black-33;
  color: $linked-text-hover;
}




blockquote {
  border-left: 4px solid $gray-13;
  color: $gray-07;
  font-style: italic;
  margin-left: 0;
  padding-left: 1.5em;
  padding-right: 2em;
}

pre, code {
  background: $gray-14;
  border: 1px solid $gray-13;
}

pre {
  border-left: 4px solid $gray-13;
  font-size: 14px;
  line-height: 1.4;
  padding: 1em;
}

// ********************************************************************
// 2. Masthead area
//
//


.topnav {
  font-family: Dosis;
  font-size: 17px;
  font-weight: 400;
  list-style: none;
  margin: 0 auto;
  max-width: 768px;
  padding: 2em 0 0;
  text-align: center;
  text-transform: uppercase;

  li {
    display: inline-block;
    // float: left;
    list-style: none;
    margin-bottom: 0;
    margin-right: 1em;

    a {
      // background: $gray-14;
      background: none;
      // border: 1px solid $gray-14;
      border: 0;
      border-bottom: 1px solid transparent;
      color: $linked-text;
      display: inline-block;
      padding: .6em .15em .1em;
      text-decoration: none;
      transition: border-bottom .5s ease;

      &:hover {
      // background: $gray-13;
      // border-color: $gray-13;
        border-bottom: 1px solid $lila;
        color: $lila;
      }
    }

    &.current {
      a {
      // background: $gray-13;
      // border-color: $gray-13;
      }
    }

    &.link { //
    }

    &.edit {
      margin-top: .5em;

      a { //

        &:link,
        &:visited {
          background-color: $edit-link-bg-color;
          border: 1px solid $edit-link-border-color;
          color: $edit-link-text;
          display: inline;
          font-family: 'Roboto Slab', serif;
          font-size: 11px;
          font-weight: 400;
          letter-spacing: 0;
          padding: 2px 5px;
          text-transform: none;
          transition: border .5s ease, background-color .5s ease;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: $edit-link-bg-color-hover;
          border-color: $edit-link-border-color-hover;
          color: $edit-link-text-hover;
        }
      }

      &.admin { //
      }
    }
  }
}



.languages {
  float: right;
  font-size: 80%;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 30%;

  a {
    border: 0;
    border-left: 1px solid $gray-12;
    display: inline;
    padding: 0 .5em;
  }

  li {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;

    &.current a {
      font-weight: bold;
    }

    &:first-child a {
      border: 0;
      padding-left: 0;
    }
  }
}





form.search {
  float: right;
  margin: 0;
  padding-bottom: 1em;
  width: 30%;

  input {
    border: 1px solid $gray-12;
    margin: 0;
    padding: .25em .5em;
    width: 100%;
  }
}



.breadcrumbs {
  clear: both;
  float: left;
  font-size: 80%;
  padding-top: 1em;
  width: 70%;
}

.breadcrumbs span:after {
  color: $gray-09;
  content: ">";
  padding-left: .5em;
  padding-right: .25em;
}

// ********************************************************************
// 3. Main content and sidebar
//
//

#main {
  // border-top: 1px solid $gray-14;
  border-top: 0;
  clear: both;
  margin-top: 1em;
  padding-top: 1em;
}

#content {
  // width: 85%;
  width: 100%;
}

#content,
#sidebar {
  // padding-bottom: 2em;
  padding-bottom: 0;
}

body.has-sidebar #content {
  width: 65%;
  float: left;
}

body.has-sidebar #sidebar {
  float: left;
  padding-left: 5%;
  width: 35%;
}

.nav {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  .nav {
    list-style: disc;
    padding-left: 1.5em;
  }

  li {
    margin: 1em 0;
  }

  .current > a {
    color: $linked-text;
  }

  a {
    font-weight: bold;
  }

}

.nav-tree li {
  margin-bottom: 0;
  margin-top: 0;

  a {
    color: $gray-07;
  }
}





#sidebar img + blockquote {
  margin-top: 0;
}

.align_left {
  // for images placed in rich text editor
  float: left;
  margin: 0 1em .5em 0;
  max-width: 50%;
  position: relative;
  top: .5em;
}

.align_right {
  // for images placed in rich text editor
  float: right;
  margin: 0 0 .5em 1em;
  max-width: 50%;
}

.align_center {
  // for images placed in rich text editor
  display: block;
  margin: 1em auto;
  position: relative;
  top: .5em;
}

figure {
  display: table;
  margin: 1em 0;
  width: 1px;
}

figure img {
  display: table-row;
  margin-bottom: .5em;
}

figure figcaption {
  color: $gray-07;
  display: table-row;
  font-size: smaller;
  line-height: 1.4;
}

// ********************************************************************
// 4. Page Footer
//
//


.content {
  .section.section--footer {
    font-size: 80%;
    font-weight: 300;
    line-height: 1.15;
    margin: 0;
    // padding: 0;
    padding: 1em 3em;

    .section--inner {
      // border-top: 1px solid $gray-14;
      border-top: 0;
      clear: both;
      margin: 0 auto;
      max-width: 768px;
      padding: 0;

      p {
        // margin: 1em 0 .1em;

        // a:link,
        // a:visited {
        //   border-bottom-color: $white-80;
        //   color: $white;
        // }

        // a:hover,
        // a:active,
        // a:focus {
        //   border-bottom-color: $black-50;
        //   color: $black-50;
        // }
      }
    }
  }
}

// ********************************************************************
// 5. Media queries for responsive layout
//
//

@media only screen and (max-width: 767px) {
  // mobile layout

  .languages {
    margin-bottom: 1em;
    width: 100%;
  }

  .topnav {
    clear: both;
    float: none;
    width: 100%;
  }

  .breadcrumbs {
    margin-bottom: 1em;
    margin-top: 0;
  }

  body, td, textarea {
    font-size: 100%;
  }

  body.has-sidebar #content,
  body.has-sidebar #sidebar {
    float: none;
    padding: 0;
    width: 100%;
  }

  form.search {
    float: none;
    padding-bottom: 0;
    width: 100%;
  }

  #content {
    width: 100%;
  }

  #sidebar {
    border-top: 1px solid $gray-14;
    padding-top: 1em;
  }

  .align_left,
  .align_right,
  .align_center {
    display: block;
    float: none;
    margin: 1em auto;
    max-width: 100%;
  }

}

@media only screen and (min-width: 1200px) {
// extra-wide desktop layout

  body, td, textarea {
    font-size: 115%;
  }
}

// ********************************************************************
// 6. Accessibility helpers
//
//

// Hide visually, but remain approachable for screenreader

.visually-hidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Show bypass link on hover

.element-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
}

// Sample styling for bypass link

.bypass-to-main:focus {
  background: $gray-03;
  color: $white;
  height: 40px;
  left: 0;
  line-height: 40px;
  text-align: center;
  top: 0;
  width: 100%;
}


h1 .edit a,
h2 .edit a,
h3 .edit a {
  background-color: $edit-link-bg-color;
  border: 1px solid $edit-link-border-color;
  font-size: 11px;
  font-weight: 400;
  margin-left: 1em;
  padding: 2px 5px;
  transition: border .5s ease, background-color .5s ease;
  vertical-align: middle;

  &:hover {
    background-color: $edit-link-bg-color-hover;
    border-color: $edit-link-border-color-hover;
  }
}




// Slider

.carousel {
  margin: 1em 0 3em;
}


.carousel:after {
  content: '';
}

@media screen and ( min-width: 430px ) {
  /* ensable Flickity for larger devices */
  .carousel:after {
    content: 'flickity';
    display: none; /* hide :after */
  }
}


.flickity-resize {
  .cell {
    min-height: 100%;
  }
}


.flickity-enabled .cell {
  display: flex;
  margin-left: 20em;
}

.cell {
  // background-color: $carousel-bg;
  padding: 1.5em 2em 1.8em;
  width: 100%;

  // &:nth-child(even) {
  //   background-color: $carousel-bg-light;
  // }

  .cell--inner {
    align-self: center;
    text-align: center;
    width: 100%;

    .body {
      font-size: 130%;
      font-style: italic;
    }

    .author {
      color: $dark-font;
      font-weight: 300;
      min-height: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  // mobile layout

  .cell {
    .cell--inner {
      .body {
        font-size: 15px;
      }

      .author {
        font-size: 12px;
      }
    }
  }

} // END: @media


// Section Header

.section.section--header {
  .section--inner {

    padding: 0 3em 1em;
    text-align: center;

    h1 {
      font-size: 42px;
      font-weight: 200;
      line-height: 1.12;
      margin-bottom: .5em;
      margin-top: .5em;
      text-align: center;
    }

    p:last-child {
      margin-bottom: 3em;
    }

    .logo {
      text-align: center;

      svg {
        height: auto;
        margin-bottom: 3.5em;
        margin-top: 3.5em;
        max-width: 280px;
        width: 75%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  // mobile layout

  .section.section--header {
    .section--inner {
      padding: 0 5% 1em;

      h1 {
        font-size: 32px;
      }

      .logo {
        svg {
          width: 100%;
        }
      }
    }
  }

} // END: @media




// Content without Sidebar

#content {
  width: 100%;
}

// Sections

.section {
  padding: 1em 3em;
  position: relative;

  strong {
    color: $black-50;
    font-size: 106.25%; // = 16px -> 17px
    font-weight: 700;
  }

  .section--inner {
    margin: 0 auto;
    max-width: 768px;
  }
}

// Section with Dark Background
.section:nth-child(even) {
  background-color: $lila-light;
  color: $white;
  font-weight: 200;
  padding-bottom: 2.25em;

  .edit a {

    &:link,
    &:visited {
      color: inherit;
      border-bottom-color: $edit-link-border-color;
    }

    &:hover,
    &:active,
    &:focus {
      color: inherit;
      border-bottom-color: $edit-link-border-color-hover;
    }
  }


  a:link,
  a:visited {
    border-bottom-color: $white-80;
    color: $white;
  }

  a:hover,
  a:active,
  a:focus {
    border-bottom-color: $gray-04;
    color: $gray-04;
  }

  strong {
    a:link,
    a:visited {
      border-bottom-color: $black-50;
      color: $black-50;
    }

    a:hover,
    a:active,
    a:focus {
      border-bottom-color: $white-80;
      color: $white;
    }
  }
}

// Section with Light Background
.section:nth-child(odd) {
  margin-bottom: 2.25em;
  margin-top: 1.25em;

  .edit a {

    &:link,
    &:visited {
      color: inherit;
      border-bottom-color: $edit-link-border-color;
    }

    &:hover,
    &:active,
    &:focus {
      color: inherit;
      border-bottom-color: $edit-link-border-color-hover;
    }
  }

  a:link,
  a:visited {
    border-bottom-color: $gray-04;
    color: $gray-04;
  }

  a:hover,
  a:active,
  a:focus {
    border-bottom-color: $lila;
    color: $lila;
  }
}


// Logo Animation


.word--weaver svg {
  flex: 0 1 auto;
  max-width: 100%;
}

.letter-solid {
  fill: $lila;
  transform-origin: 50% 50%;
}

.letter-weaved {
  fill: none;
  opacity: 0;
  stroke: $lila;
  stroke-width: 3px;
}

// About Me Image Border Radius

.section.about-me .section--inner img.align_left {
  border-radius: 4px;
}

.section.about-me .section--inner img {
  box-shadow: 2px 2px 15px $black-15;
}

// Back To Top Link

.back-to-top {
  background-color: $white;
  border-radius: 50%;
  bottom: 30px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  opacity: .3;
  padding: 1px 4px;
  position: fixed;
  right: 30px;
  transition: opacity .5s ease-in-out;
  z-index: 2000;

  p {
    margin: 0;

    a {
      border: 0;
    }
  }

  &:hover {
    opacity: .75;
  }
}

// Work Samples

.section.my-work {
  .work-samples {
    margin-top: 3em;

    .work-sample {
      border-top: 1px solid $black-08;
      display: flex;
      flex-direction: column;
      padding-bottom: 1em;
      padding-top: 1em;
      text-align: center;

      &:last-child {
        border-bottom: 1px solid $black-08;
      }

      .work-sample--image {
        line-height: 0;
        margin-bottom: 1em;
        margin-right: 0;
        vertical-align: top;
        width: 100%;

        img {
          height: auto;
          max-width: 320px;
          width: 100%;
        }
      }

      .work-sample--copy {
        margin-top: 1em;
        // min-width: 40%;


        .headline {
          border-top: 0 none;
          line-height: 1.25;
          margin-bottom: 0;
          margin-top: 0;
          padding-top: 0;
        }
      }
    }
  }
}



// Two column for larger Screens

@media only screen and (min-width: 720px) {

  .section.my-work {
    .work-samples {
      .work-sample {
        flex-direction: row;
        text-align: left;

        .work-sample--image {
          margin-right: 3.3%;
          width: 41.7%;
        }

        .work-sample--copy {
          display: inline-block;
          margin-top: 0;
          width: 55%;
        }
      }
    }
  }

} // END: @media



// Fix footer on bottom for Impressum & Privacy Policy

.tpl-page {

  body {
    position: relative;
    min-height: 100vh;
    padding-bottom: 66px;    /* Footer height */
  }


  .section--footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 66px;            /* Footer height */
  }

}


// Hide Sitename in footer for mobile

@media only screen and (max-width: 420px) {

  .section--footer .sitename {
    display: none;
  }

} // END: @media





