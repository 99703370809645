// Styles for the AAGD Page Header Bar Module


// MOBILE

.header.module--page-header-bar {
  // background-color: $black-08;
  background-color: $white;
  border-bottom: 1px solid $lila-25;
  display: block;
  font-size: 16px;
  left: 0;
  line-height: 1;
  // padding: 0 3em;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;

  .header--inner {
    margin: 0 auto;
    max-width: 768px;
    padding: 8px 0 6px;
    text-align: center;

    svg {
      // display: none;
      margin-bottom: 4px;
      margin-right: 6px;
      vertical-align: middle;
    }

    .primary {
      // display: none;
    }

    .divider {
      display: none;
    }

    .secondary {
      display: none;
    }
  }
}


// TABLET

@media only screen and (min-width: 520px) {

  .header.module--page-header-bar {
    // font-size: 14px;

    .header--inner {
      margin: 0 auto;
      max-width: 768px;
      padding: 8px 0 6px;
      text-align: center;

      svg {
        // display: none;
        margin-bottom: 4px;
        margin-right: 6px;
        vertical-align: middle;
      }

      .primary {
        // display: none;
      }

      .divider {
        display: inline;
      }

      .secondary {
        display: inline;
      }
    }
  }

}


// DESKTOP

@media only screen and (min-width: 640px) {

  .header.module--page-header-bar {
    // font-size: 16px;
  }

}


.headroom {
  transform: translateY(-100%);
  will-change: transform;
}

.headroom--top {
  transition: transform 200ms linear;
}

.headroom--not-top {
  transform: translateY(0%);
  transition: transform 200ms linear;
}



// // headroom.js animations
// // http://wicky.nillia.ms/headroom.js/

// .headroom--pinned {
//   animation-name: slideDown;
// }

// .headroom--unpinned {
//   animation-name: slideUp;
// }

// .headroom--pinned, .headroom--unpinned {
//   animation-duration: .5s;
//   animation-fill-mode: both;
// }

// // Keyframes slideUp Animation ----------------------------

// @keyframes slideUp {
//     0% { transform: translateY(0)     }
//   100% { transform: translateY(-100%) }
// }

// // Keyframes slideDown Animation --------------------------

// @keyframes slideDown {
//     0% { transform: translateY(-100%) }
//   100% { transform: translateY(0)     }
// }



// END: AAGD Page Header Bar Module
